
/* Général */
$body_background_color : #eee;
$body_texte_color: #000;
$link_color : #000;
$link_hover_color : #b0b0b0;

$titlesection_color : #fff;
$titlesection_background_color : #000;
$titlesection_before_bordercolor : transparent #000 transparent transparent;
$titlesection_after_bordercolor : transparent transparent transparent #000;

/* Header */
$header_background_color : #fff;
$slogan_color : #000;
 
$nav_color : #fff;
$nav_background_color : #b0b0b0;
$nav_a_before_bordercolor : transparent #b0b0b0 transparent transparent;
$nav_a_after_bordercolor : transparent transparent transparent #b0b0b0;
$sousnav_color: #fff;
$sousnav_background_color : #b0b0b0;
$sousnav_hover_color : #fff;
$sousnav_hover_background_color : #000;
$nav_actif_color : #fff; 
$nav_actif_background_color : #000;
$nav_actif_before_bordercolor : transparent #000 transparent transparent;
$nav_actif_after_bordercolor : transparent transparent transparent #000;

/* Content */
$content_background_color : #eee;
$navarianne_borderbottom_color : #b0b0b0;
$navarianne_borderbottom_hover_color : #000;
$navarianne_actif_color : #000;
$navarianne_actif_background_color : #fff;
$navarianne_actif_bordercolor : #000;
$button_background_color : #000;
$button_color : #fff;
$button_border_color: #000;
$button_hover_color : #000;
$button_hover_background_color : #fff;

$formperso_titre_color: #000;


/* accueil */
$slidetext_color: #000;
$slidetext_background_color : #fff;


/* boutique */
$etiquette_color : #fff;
$etiquette_background_color : #b0b0b0; 
$produits_mask_color : #fff; 
$produits_mask_background_color : rgba(37, 37, 37, 0.6);
$produits_zoom_color : #000;
$produits_zoom_background_color : #fff;
$produits_zoom_hover_color : #fff;
$produits_zoom_hover_background_color : #000;
$oldprix_color : #8c8c8c;
$prix_color: #000;

$ficheproduit_triangleremise_bordercolor: #b0b0b0;
$ficheproduit_oldprix_color: #8c8c8c;
$ficheproduit_fleches_color: #000;

$searchbaricon_color : #fff;
$searchbaricon_background_color :#000;  


/* galerie */
$photo_mask_background_color : #000;
$photo_description_color : #fff;
$photo_description_background_color : #fff;
$photo_commentaires_color : #fff;

/* livre d'or */
$msglo_texte_color: #000; 
$msglo_h4_color: #000;
$msglo_h4_background_color: #b0b0b0;
$msglo_note_color: #fff;

/* contact */
$form_focus_shadow: 0 0 5px #b0b0b0;
$form_focus_bordercolor: #b0b0b0;


/* Footer */
$footer_color : #fff;
$footer_background_color : rgb(34, 34, 34); 

/* Blog */
$background_blog_menu :  #b0b0b0;
$background_blog_h2 : #000;
$white : #fff;

/* Panier template 2 */
$panier_background_color_2:#000;
$panier_texte_color_2:#fff;
/* Template Panier */
$panier-background-color:#fff;
$panier-titre-color:#000;
$panier-content-background-color:#fff;
$panier-texte-color:#000;

/* Flux panier */
$flux-panier_color-shadow:black;

@import "design/732series/declinaison";  

